import styled, { css } from "styled-components"
import { transparentize } from "polished"

const linkStyles = css`
  text-decoration: none;
  display: block;
  text-align: center;
`

export const buttonStyles = css`
  outline: none;
  ${({ as }) => as === "a" && linkStyles};
  ${({ width }) => width && `width: ${width};`}

  cursor: pointer;
  font-family: ${({ theme }) => theme.font.family.body};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  border: solid 2px transparent;
  width: fit-content;
  display: flex;
  align-items: center;

  /* Primary = orange */
  ${({ type }) =>
    (type === "primary" || type === "submit") &&
    css`
      border-color: ${({ theme }) => theme.color.septenary03};
      color: ${({ theme }) => theme.color.white};
      background-color: ${({ theme }) => theme.color.septenary03};

      &:hover {
        border-color: ${({ theme }) => theme.color.septenary02};
        background-color: ${({ theme }) => theme.color.septenary02};
      }
      &:active {
        transition: none;
        border-color: ${({ theme }) => theme.color.septenary01};
        background-color: ${({ theme }) => theme.color.septenary01};
      }
    `}

  /* Secondary = ghost */
  ${({ type }) =>
    type === "secondary" &&
    css`
      border-color: ${({ theme }) => theme.color.secondary04};
      background-color: transparent;
      color: ${({ theme }) => theme.color.secondary01};

      &:hover {
        background-color: ${({ theme }) => theme.color.secondary04};
      }

      &:active {
        border-color: ${({ theme }) => theme.color.secondary03};
        background-color: ${({ theme }) => theme.color.secondary03};
      }

      ${({ hasDarkBg }) =>
        hasDarkBg &&
        css`
          border-color: ${({ theme }) =>
            transparentize(0.5, theme.color.white)};
          color: ${({ theme }) => theme.color.white};

          &:hover {
            border-color: transparent;
            background: ${({ theme }) =>
              transparentize(0.76, theme.color.white)};
          }

          &:active {
            transition: none;
            border-color: transparent;
            background: ${({ theme }) =>
              transparentize(0.6, theme.color.white)};
          }
        `}
    `}

  /* Secondary = filled */
    ${({ type }) =>
      type === "secondaryFilled" &&
      css`
        border-color: ${({ theme }) => theme.color.secondary02};
        background-color: ${({ theme }) => theme.color.secondary02};
        color: ${({ theme }) => theme.color.white};

        &:hover {
          border-color: ${({ theme }) => theme.color.secondary01};
          background-color: ${({ theme }) => theme.color.secondary01};
        }

        &:active {
          transition: none;
          border-color: ${({ theme }) => theme.color.secondary01};
          background-color: ${({ theme }) => theme.color.secondary01};
        }
      `}

  /* Tertiary = blue */
  ${({ type }) =>
    type === "tertiary" &&
    css`
      border-color: ${({ theme }) => theme.color.main03};
      background-color: ${({ theme }) => theme.color.main03};
      color: ${({ theme }) => theme.color.white};

      &:hover {
        border-color: ${({ theme }) => theme.color.main02};
        background-color: ${({ theme }) => theme.color.main02};
      }

      &:active {
        border-color: ${({ theme }) => theme.color.main01};
        background-color: ${({ theme }) => theme.color.main01};
      }
    `}

    ${({ type }) =>
      type === "white" &&
      css`
        border-color: ${({ theme }) => theme.color.white};
        background-color: ${({ theme }) => theme.color.white};
        color: ${({ theme }) => theme.color.black};

        &:hover {
          border-color: ${({ theme }) => theme.color.secondary05};
          background-color: ${({ theme }) => theme.color.secondary05};
        }

        &:active {
          transition: none;
          border-color: ${({ theme }) => theme.color.secondary05};
          background-color: ${({ theme }) => theme.color.secondary05};
        }
      `}

  ${({ size }) =>
    size === "large" &&
    css`
      min-height: 5.6rem;
      padding: 0 3.2rem;
      font-size: ${({ theme }) => theme.font.size.md};
      line-height: 1.75;
    `}

  ${({ size }) =>
    size === "medium" &&
    css`
      min-height: 4rem;
      padding: 0.8rem 2.4rem;
      font-size: ${({ theme }) => theme.font.size.s};
      line-height: 1;
    `}
`

const StyledButton = styled.button`
  ${buttonStyles};
  ${({ extraStyles }) => extraStyles};
`

const LinkStyles = css`
  ${linkStyles}
  ${buttonStyles}
`

export { StyledButton, LinkStyles }
