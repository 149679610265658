import styled from "styled-components"

const StyledSpan = styled.span`
  font-size: inherit;
  line-height: inherit;
  color: ${({ theme }) => theme.color.main03};
  cursor: pointer;
`

export { StyledSpan }
