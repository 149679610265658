import { createGlobalStyle } from "styled-components"
import { h1Sizes, h2Sizes, h3Sizes, h4Sizes } from "./mixins"
import { modalStyles } from "./modal"
import "./fonts.css"

const GlobalStyle = createGlobalStyle`
  #gatsby-focus-wrapper {
    min-height: 100vh;
    
    ${({ theme }) => theme.mq.medium} {
      min-height: initial;
    }
  }

  html {
    box-sizing: border-box;
    font-size: 62.5%; /* easier rem conversion, for ex. 16px = 1.6rem */
  }
  *, *::before, *::after {
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body, p, span {
    margin: 0;
    padding: 0;
    font-family: ${({ theme }) => theme.font.family.body};
    font-size: 1.6rem;
    font-variant-numeric: lining-nums;
  }
  body > div {
    background-color: ${({ theme }) => theme.color.white};
  }
  button, a {
    font-family: ${({ theme }) => theme.font.family.body};
  }
  a {
    text-decoration: none;
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: ${({ theme }) => theme.font.family.headers};
    font-weight: 700;
    font-variant-numeric: lining-nums;
  }
  h1 {
    ${h1Sizes};
  }
  h2 {
    ${h2Sizes};
  }
  h3 {
    ${h3Sizes};
  }
  h4 {
    ${h4Sizes};
  }

  .cluster {
    > img {
      width: 100%!important;
    }
  }

  .infoBox {
    overflow: visible!important;
    > img {
      display: none;
    }
  }

  .gmnoprint.gm-style-mtc {
     width: 11rem!important;

    > button {
      width: 100%;
    }
  }


  ${modalStyles}
`

export default GlobalStyle
