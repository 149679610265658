import styled, { css } from "styled-components"

const StyledLine = styled.span`
  width: 50%;
  height: 0.3rem;
  transition: 0.3s ease-in-out;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 2px;
  background-color: ${({ theme }) => theme.color.secondary01};

  top: ${({ top }) => top};
  ${({ openStyles }) =>
    openStyles &&
    css`
      ${openStyles}
    `};
`

export { StyledLine }
