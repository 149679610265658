/* eslint-disable no-nested-ternary */
import React from "react"
import PropTypes from "prop-types"
import trackCustomEventTrigger from "utils/trackCustomEventTrigger"

import { StyledA, StyledLink, StyledPlaceholer } from "./BtnLink.styles"

const BtnLink = ({ linkStyles, trackingOptions, ...rest }) => {
  const internal = /^\/(?!\/)/.test(rest.to)
  return internal ? (
    <StyledLink
      {...rest}
      linkStyles={linkStyles}
      onClick={() => {
        if (trackingOptions) trackCustomEventTrigger(trackingOptions)
        if (rest && rest.onClick) rest.onClick()
      }}
    />
  ) : rest.to ? (
    <StyledA
      href={rest.to}
      target="_blank"
      rel="noopener noreferrer"
      linkStyles={linkStyles}
      {...rest}
      onClick={() => {
        if (trackingOptions) trackCustomEventTrigger(trackingOptions)
        if (rest && rest.onClick) rest.onClick()
      }}
    >
      {rest.children}
    </StyledA>
  ) : (
    <StyledPlaceholer
      linkStyles={linkStyles}
      {...rest}
      onClick={() => {
        if (trackingOptions) trackCustomEventTrigger(trackingOptions)
        if (rest && rest.onClick) rest.onClick()
      }}
    >
      {rest.children}
    </StyledPlaceholer>
  )
}

BtnLink.propTypes = {
  to: PropTypes.string,
  children: PropTypes.any.isRequired,
  linkStyles: PropTypes.any,
  trackingOptions: PropTypes.shape(),
}

BtnLink.defaultProps = {
  linkStyles: "",
  to: null,
  trackingOptions: null,
}

export default BtnLink
