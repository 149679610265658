import { css } from "styled-components"
import { transparentize } from "polished"

const modalStyles = css`
  .ReactModal__Body--open,
  .ReactModal__Html--open {
    overflow: hidden;
  }

  .ReactModal__Overlay:not(.Secondary) {
    background: ${({ theme }) =>
      transparentize(0.2, theme.color.secondary01)}!important;
    z-index: 102;

    .ReactModal__Content {
      top: 0 !important;
      left: 0 !important;
      right: 0 !important;
      bottom: 0 !important;

      ${({ theme }) => theme.mq.medium} {
        top: 4.8rem !important;
        bottom: 4.8rem !important;
        right: 4.8rem !important;
        left: 4.8rem !important;
      }

      padding: 0 !important;
      max-width: 77.8rem;
      margin: 0 auto;
      border: 0 !important;
      border-radius: 0 !important;
    }
  }
  .ReactModal__Content--after-open {
    ${({ theme }) => theme.mq.medium} {
      margin-right: -0.6rem;
      padding: 0 1rem 0 0;

      &::-webkit-scrollbar-track {
        background-color: ${({ theme }) => theme.color.secondary06};
      }

      &::-webkit-scrollbar {
        width: 6px;
        background-color: ${({ theme }) => theme.color.secondary06};
      }

      &::-webkit-scrollbar-thumb {
        background-color: ${({ theme }) => theme.color.secondary03};
      }
    }
  }
`

export { modalStyles }
