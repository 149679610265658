import React from "react"
import { StyledLine } from "./CloseIcon.styles"

const CloseIcon = (props) => {
  const renderLines = () => {
    const lines = [
      {
        index: 0,
        top: "50%",
        openStyles: "transform: translate(-50%, -50%) rotate(45deg);",
      },
      {
        index: 1,
        top: "50%",
        openStyles: "transform: translate(-50%, -50%) rotate(-45deg);",
      },
    ]

    return lines.map((el) => (
      <StyledLine
        key={el.index}
        top={el.top}
        openStyles={el.openStyles}
        {...props}
      />
    ))
  }
  return <>{renderLines()}</>
}

export default CloseIcon
