import React, { useRef } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import ReactTooltip from "react-tooltip"

const StyledTooltip = styled(ReactTooltip)`
  border-radius: 0;
  padding: 0.6rem 1.6rem;
  background: ${({ theme }) => theme.color.white};
  font-size: 1.2rem !important;
  line-height: 1.8rem !important;
  opacity: 1 !important;
  /* important needed to override plugin styles  */

  &:after,
  &:before {
    display: none;
  }
`

const Tooltip = ({ children, customAfterShow, hideAfter, ...props }) => {
  const ref = useRef()
  return (
    <StyledTooltip
      ref={ref}
      afterShow={
        customAfterShow
          ? (...args) => {
              customAfterShow(...args)
              setTimeout(ref.current.hideTooltip, hideAfter || 1500)
            }
          : () => setTimeout(ref.current.hideTooltip, hideAfter || 1500)
      }
      {...props}
    >
      {children}
    </StyledTooltip>
  )
}

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  hideAfter: PropTypes.number,
  customAfterShow: PropTypes.func,
}

Tooltip.defaultProps = {
  hideAfter: 0,
  customAfterShow: null,
}

export default Tooltip
