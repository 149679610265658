/* eslint-disable react/display-name */
import React from "react"
import reactStringReplace from "react-string-replace"
import patterns from "utils/patterns"
import BtnLink from "components/atoms/BtnLink"
import CopyLink from "components/atoms/CopyLink"

import { css } from "styled-components"

const linkStyles = css`
  font-size: inherit;
  line-height: inherit;
  color: ${({ theme }) => theme.color.main03};
`

const linkTpes = {
  link: (props) => {
    const [to, text] = props
    return (
      <BtnLink linkStyles={linkStyles} to={to} key={to + text}>
        {text}
      </BtnLink>
    )
  },
  copy: (props) => {
    const [toCopy] = props
    return <CopyLink props={props} key={toCopy} />
  },
}

const createLink = (string) =>
  reactStringReplace(string, patterns.customLinkMarkup, (matchingElement) => {
    const type = matchingElement
      .match(patterns.contentInRoundBracelets)
      .map((res) => res.replace(patterns.roundBracelets, ""))

    const element = matchingElement
      .match(patterns.contentInCurlyBracelets)
      .map((res) => res.replace(patterns.curlyBracelets, ""))
    return linkTpes[type](element)
  })

export default createLink
