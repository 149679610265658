import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import createLink from "utils/createLink"

const StyledParagraph = styled.p`
  font-family: ${({ theme }) => theme.font.family.body};
  font-size: ${({ theme }) => theme.font.size.s};
  line-height: 1.71;
  color: ${({ theme }) => theme.color.secondary01};
  ${({ extraStyles }) => extraStyles};
`

const P = ({ children, ...props }) => (
  <StyledParagraph {...props}> {createLink(children)} </StyledParagraph>
)

P.propTypes = {
  props: PropTypes.any,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
}

P.defaultProps = {
  props: null,
}

export default P
