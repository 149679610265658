import React, { useCallback } from "react"
import PropTypes from "prop-types"
import { useClipboard } from "use-clipboard-copy"

import Tooltip from "components/atoms/Tooltip"
import { StyledSpan } from "./CopyLink.styles"

const CopyLink = ({ props }) => {
  const [toCopy, text] = props
  const clipboard = useClipboard()
  const copyToClipboard = useCallback(
    (clipboardContent) => {
      clipboard.copy(clipboardContent)
    },
    [clipboard.copy]
  )

  return (
    <>
      <Tooltip
        id={toCopy}
        place="right"
        effect="solid"
        type="light"
        clickable
        customAfterShow={() => {
          copyToClipboard(toCopy)
        }}
        wrapper="span"
      >
        Copied
      </Tooltip>
      <StyledSpan type="button" data-tip data-for={toCopy} data-event="click">
        {text}
      </StyledSpan>
    </>
  )
}

CopyLink.propTypes = {
  props: PropTypes.array.isRequired,
}

export default CopyLink
