import { css } from "styled-components"

const fontSizeFormatter = (
  mobileSize,
  mobileHeight,
  tabletSize,
  tabletHeight
) => css`
  font-size: ${mobileSize}rem;
  line-height: ${mobileHeight}rem;

  ${({ theme }) => theme.mq.medium} {
    font-size: ${tabletSize}rem;
    line-height: ${tabletHeight}rem;
  }
`

export const h1Sizes = fontSizeFormatter(4.8, 6.0, 7.2, 8.0)
export const h2Sizes = fontSizeFormatter(3.2, 4.0, 4.8, 6.0)
export const h3Sizes = fontSizeFormatter(2.0, 2.4, 3.2, 4.0)
export const h4Sizes = fontSizeFormatter(2.0, 2.4, 2.0, 2.4)
