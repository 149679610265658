import React from "react"
import styled from "styled-components"
import { Link } from "gatsby-plugin-intl"

const StyledA = styled.a`
  ${({ linkStyles }) => linkStyles};
  ${({ extraStyles }) => extraStyles};
`

const StyledPlaceholer = styled.span`
  ${({ linkStyles }) => linkStyles};
  ${({ extraStyles }) => extraStyles};
`

const StyledLink = styled(({ linkStyles, extraStyles, ...props }) => (
  <Link {...props} />
))`
  ${({ linkStyles }) => linkStyles};
  ${({ extraStyles }) => extraStyles};
`

export { StyledA, StyledLink, StyledPlaceholer }
