import React from "react"
import PropTypes from "prop-types"
import trackCustomEventTrigger from "utils/trackCustomEventTrigger"
import BtnLink from "components/atoms/BtnLink"

import { StyledButton, LinkStyles } from "./Button.styles"

const Button = ({ to, testId, trackingOptions, ...rest }) => {
  return to.length ? (
    <BtnLink
      {...rest}
      to={to}
      linkStyles={LinkStyles}
      onClick={() => {
        if (trackingOptions) trackCustomEventTrigger(trackingOptions)
        if (rest && rest.onClick) rest.onClick()
      }}
    />
  ) : (
    <StyledButton
      data-testid={testId}
      {...rest}
      onClick={() => {
        if (trackingOptions) trackCustomEventTrigger(trackingOptions)
        if (rest && rest.onClick) rest.onClick()
      }}
    />
  )
}

Button.propTypes = {
  to: PropTypes.string,
  type: PropTypes.string,
  size: PropTypes.string,
  testId: PropTypes.string,
  trackingOptions: PropTypes.shape({
    category: PropTypes.string,
    action: PropTypes.string,
    label: PropTypes.string,
  }),
}

Button.defaultProps = {
  to: "",
  type: "primary",
  size: "large",
  testId: "",
  trackingOptions: null,
}

export default Button
