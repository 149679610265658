import createSlug from "utils/createSlug"

const ABOUT_BASE = "/about"

const INVEST_BASE = "/invest"

const PORTFOLIO_BASE = "/portfolio"
const BLOG_BASE = "/blog"

export default {
  home: "/",
  whyExdev: "/#why-exdev",
  homepageAbout: "/#homepage-about",
  frontrowReporting: "/#frontrow-reporting",
  directToDeveloper: "/#direct-to-developer",
  seamlessExperience: "/#seamless-experience",
  exdevDifference: "/#exdev-difference",
  howItWorks: "/#how-it-works",
  popularQuestions: "/#popular-questions",
  blog: BLOG_BASE,
  article: (slug) => `${BLOG_BASE}/${createSlug(slug)}`,
  about: `${ABOUT_BASE}`,
  aboutWhatWeDo: `${ABOUT_BASE}#whatWeDo`,
  aboutSeeOurImpact: `${ABOUT_BASE}#seeOurImpact`,
  aboutSurveyOurProcess: `${ABOUT_BASE}#surveyOurProcess`,
  aboutUnderstandOurBusiness: `${ABOUT_BASE}/understand-our-business`,
  contact: "/contact",
  investInCRE: "/#investInCRE",
  investHelpAndLearning: "https://exdev.zendesk.com/hc/en-us",
  investHowItWorks: `${INVEST_BASE}/how-it-works`,
  investWhyExdev: `${INVEST_BASE}/why-exdev`,
  login: "https://investors.appfolioim.com/exdev/investor/login",
  portfolio: PORTFOLIO_BASE,
  portfolioMap: `${PORTFOLIO_BASE}/map`,
  portfolioProjectSnapshot: (slug) =>
    `${PORTFOLIO_BASE}/project/snapshot/${createSlug(slug)}`,
  portfolioProjectBriefing: (slug) =>
    `${PORTFOLIO_BASE}/project/briefing/${createSlug(slug)}`,
  portfolioProjectTimeline: (slug) =>
    `${PORTFOLIO_BASE}/project/timeline/${createSlug(slug)}`,
  portfolioProjectProgress: (slug) =>
    `${PORTFOLIO_BASE}/project/progress/${createSlug(slug)}`,
  portfolioProjectVision: (slug) =>
    `${PORTFOLIO_BASE}/project/vision/${createSlug(slug)}`,
  portfolioProjectGallery: (slug) =>
    `${PORTFOLIO_BASE}/project/gallery/${createSlug(slug)}`,
  portfolioProjectLocation: (slug) =>
    `${PORTFOLIO_BASE}/project/location/${createSlug(slug)}`,
  portfolioProjectTimelinePost: (slug) =>
    `${PORTFOLIO_BASE}/project/timeline/post/${createSlug(slug)}`,
  privacyPolicy: "/privacy",
  getStarted: process.env.GATSBY_EXDEV_REGISTRATION_LINK,
  sitemap: `/sitemap.xml`, // "/site-map",
  termsOfService: "/terms",
  underConstruction: "/under-construction",
  notFound: "/404",
}
