const breakpoints = {
  extraLarge: 1200,
  large: 992,
  medium: 768,
  small: 576,
}

const color = {
  white: "#ffffff",
  black: "#000000",
  main01: "#021F4B",
  main02: "#083F91",
  main03: "#196BE6",
  main04: "#90BAF9",
  main05: "#D7E7FE",
  secondary01: "#07162C",
  secondary02: "#53647F",
  secondary03: "#A3AFC2",
  secondary04: "#D9E2EF",
  secondary05: "#F1F5FB",
  secondary06: "#F6F9FD",
  secondary07: "#F3F7FC",
  tertiary01: "#42000C",
  tertiary02: "#AF0E2C",
  tertiary03: "#FF244D",
  tertiary04: "#F7A1B1",
  tertiary05: "#FFDBE2",
  quaternary01: "#005251",
  quaternary02: "#08A09F",
  quaternary03: "#23E7E5",
  quaternary04: "#A3FAF9",
  quaternary05: "#DBFFFF",
  quinary01: "#004D30",
  quinary02: "#089662",
  quinary03: "#11D08A",
  quinary04: "#A3FADA",
  quinary05: "#DBFFF2",
  senary01: "#5C3C00",
  senary02: "#CC8500",
  senary03: "#FFC965",
  senary04: "#FFDB99",
  senary05: "#FFF1D6",
  septenary01: "#4D0000",
  septenary02: "#A93004",
  septenary03: "#F35C25",
  septenary04: "#FCAE92",
  septenary05: "#FFDDD1",
  octonary01: "#B54CE5",
  octonary02: "#5D0386",
  gradient: "#003d97",
}

const font = {
  family: {
    headers: "Lyon Display Bold",
    body: "Inter",
    signature: "Mrs Saint Delafield",
  },
  weight: {
    regular: "400",
    semiBold: "600",
    bold: "800",
  },
  size: {
    xs: "1.2rem",
    s: "1.4rem",
    md: "1.6rem",
    l: "1.8rem",
    xl: "2.4rem",
    xxl: "3.2rem",
  },
}

const layout = {
  desktopPadding: "116px",
  mobilePadding: "16px",
}

const theme = {
  layout,
  color,
  font,
  gridWidth: "1200px",
  breakpoints,
  mq: Object.keys(breakpoints).reduce((acc, breakpoint) => {
    acc[breakpoint] = `@media (min-width: ${breakpoints[breakpoint]}px)`

    return acc
  }, {}),
}

export default theme
